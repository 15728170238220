import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 20 min. `}<meta itemProp="prepTime" content="PT20M" /></h5>
    <h5>{`Cook time: 60 min. `}<meta itemProp="cookTime" content="PT60M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`6-8`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Green beans, 1.5 lbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Cream of mushroom soup, 1 10.5 oz. can
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Milk, 1/2 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Black pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cayenne, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Fried onions or shallots, 2/3 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Whole wheat Ritz crackers, ~13 crackers (or double overall onions/shallots)
        </span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Medium pot</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Colander</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">9x13 glass pan</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Wash green beans</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Crush crackers</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Heat water in pot, to boil.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Cut green beans into ~1.5 inch pieces, then boil for 5 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`In glass pan, mix cream of mushroom soup, milk, black pepper, and cayenne.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add green beans and onions/shallots to glass pan and stir until combined. Spread evenly.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Top with crushed crackers or more onions/shallots.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Bake at 350F for 40 minutes.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      